var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{attrs:{"app":"","clipped":"","temporary":"","fixed":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.navigationItems),function(item,index){return [(index > 0 && item.section)?_c('v-divider',{key:'divider' + index}):_vm._e(),(item.section)?_c('v-subheader',{key:'subheader' + index},[_vm._v(_vm._s(item.section.toUpperCase()))]):_vm._e(),(item.children)?_c('v-list-group',{key:index,attrs:{"prepend-icon":"list"}},[_c('v-list-item',{attrs:{"slot":"activator","to":{ name: item.name }},slot:"activator"},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1),_vm._l((item.children),function(child,idx){return _c('v-list-item',{key:idx,staticClass:"pl-8",attrs:{"to":{ name: child.name }}},[_c('v-list-item-action',[_c('v-icon',{domProps:{"innerHTML":_vm._s(child.icon)}})],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(child.title)}})],1)})],2):(item.name && item.title)?_c('v-list-item',{key:index,attrs:{"to":{ name: item.name }}},[_c('v-list-item-action',[_c('v-icon',{domProps:{"innerHTML":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)],1):_vm._e()]})],2)],1),_c('v-app-bar',{attrs:{"height":"48","color":"#1876D2","clipped-left":"","dark":"","app":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-app-bar-nav-icon',_vm._g({attrs:{"dark":""},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},on))]}}])},[_c('span',[_vm._v("Navigation menu")])]),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.toolbar.title))]),(!_vm.projectSelectorEnabled)?_c('v-spacer'):_vm._e(),(_vm.projectSelectorEnabled)?_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}):_vm._e(),(_vm.projectSelectorEnabled)?_c('v-select',{staticClass:"mt-7",staticStyle:{"maxWidth":"200px"},attrs:{"dense":"","readonly":!_vm.projectSelectorChangeable,"items":_vm.managedProjects,"label":"GCP Project ID","required":"","loading":_vm.loading},on:{"change":function($event){return _vm.projectIdChanged(true)}},model:{value:(_vm.projectId),callback:function ($$v) {_vm.projectId=$$v},expression:"projectId"}}):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","dark":""},on:{"click":_vm.toggleLightDarkMode}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.themeLightDark))])],1)]}}])},[_c('span',[_vm._v("Toggle Light/Dark Mode")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","dark":"","href":_vm.config.userGuideUrl,"target":"_blank"}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.helpCircle))])],1)]}}])},[_c('span',[_vm._v("Help")])]),(this.isLoggedIn === false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","dark":""},on:{"click":_vm.login}},on),[_c('v-icon',{attrs:{"title":"login"}},[_vm._v(_vm._s(_vm.icons.login))])],1)]}}],null,false,3439672273)},[_c('span',[_vm._v("Login")])]):_vm._e(),(this.isLoggedIn === true)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","dark":""},on:{"click":_vm.logout}},on),[_c('v-icon',{attrs:{"title":"logout"}},[_vm._v(_vm._s(_vm.icons.logout))])],1)]}}],null,false,1308768568)},[_c('span',[_vm._v("Logout")])]):_vm._e(),(this.isLoggedIn === true)?_c('v-avatar',{attrs:{"size":"28"}},[(_vm.user && _vm.user.data && _vm.user.data.photoURL)?_c('img',{attrs:{"src":_vm.user.data.photoURL,"alt":_vm.user.data.displayName}}):_vm._e()]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }